import {
  faRightFromBracket,
  faServer
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import * as links from "../../../utils/links";
import "./Main.css";

const Main = (props) => {
  const { children } = props;
  const { setDataUser, authInfo } = useContext(AuthContext);

  let navigate = useNavigate();

  const { dataUser } = authInfo;

  return (
    <>
    <div style={{ display: 'flex', minWidth: '100vw', minHeight: '100vh', flexDirection: 'column'}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px', padding: '0 24px', flexDirection: 'row', width: '100vw', background: '#e6e6e6', height: '80px'}}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px'}}>
          <div className="circle-icon">
            <span>PB</span>
          </div>
          <Link to={
              dataUser?.key === "CLIENT"
                ? links.DASHBOARD_FORNECEDOR
                : links.DASHBOARD_CLIENTES
            }>
            <Button style={{ borderRadius: '8px', height: '40px'}}> <FontAwesomeIcon icon={faServer} style={{ width: '16px', height: '16px', paddingRight: '8px'}} /> {dataUser?.key === "CLIENT" ? `Maquinas` : `Clientes`}</Button>
          </Link>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px'}}>
          <Button
              style={{ height: '40px', borderRadius: '8px'}}
                onClick={() => {
                  navigate(
                    dataUser?.key === "CLIENT" ? links.SIGNIN : links.ADMIN_SIGNIN
                  );
                  setDataUser(null);
                }}
              >
                <span style={{ fontSize: "14px", fontWeight: '600' }}>{dataUser.name}</span>
                <FontAwesomeIcon
                  className="icon"
                  style={{ width: '16px', height: '16px', paddingLeft: '8px'}}
                  icon={faRightFromBracket}
                />
            </Button>
          </div>
        </div>
        <div style={{ padding: '32px 16px'}}>
          {children}
        </div>
      </div>
    </>
  );
};

export default Main;
