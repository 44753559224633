import './home.css'
import { Button } from 'antd'
import { FaSign, FaSignInAlt } from 'react-icons/fa'
import * as links from "../utils/links";
import { useNavigate } from "react-router-dom";
import logo from '../assets/images/logo.png';

const Home = (props) => {
    let navigate = useNavigate();
    return (
        <div className='main'>

            <div className='lp'>

                <div className='wrapper'>
                    <img src={logo} alt="Logo" />
                    <div className='home'>
                        <div style={{ display: 'flex', gap: '12px', flexDirection: 'column' }}>
                            <h1 style={{ marginBottom: '0', fontWeight: '700', fontSize: '72px' }}>Paybom</h1>
                            <p style={{ fontSize: '16px', width: '320px', lineHeight: '1.2', marginBottom: '0' }}>A plataforma que oferece sistema automatizado para máquinas de venda de pelúcias.</p>

                            <Button
                                onClick={() => {
                                    navigate(links.SIGNIN);
                                }}
                                style={{ borderRadius: '8px', width: '128px' }}
                                type="primary"
                                size="large"
                            >
                                Entrar
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='lp'>

                <div>
                    {/* <div style={{ width: '64px', height: '64px', borderRadius: '32px', backgroundColor: '#000', position: 'absolute', top: '256px', left: '50%', transform: 'translate(0, -50%)' }} /> */}
                    <div style={{ display: 'flex', gap: '12px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <h1 style={{ marginBottom: '0', fontWeight: '700', fontSize: '64px', width: '560px', textAlign: 'center' }}>Sistema rapído e barato para a sua renda extra!</h1>
                        {/* <p style={{ fontSize: '16px', width: '240px', lineHeight: '1.2', marginBottom: '0' }}>A plataforma que oferece sistema automatizado para máquinas de venda de pelúcias.</p> */}

                        <Button
                            onClick={() => {
                                navigate(links.SIGNIN);
                            }}
                            style={{ borderRadius: '8px', width: '240px', height: '48px' }}
                            type="primary"
                            size="large"
                        >
                            Comece agora
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Home